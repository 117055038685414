import {currentUserChangeDispatcher} from './user'
import {BACKEND} from '~/config'
import {handleHttpError} from '~/services/error'
import {http} from '~/utils/http'

const audioElement = document.createElement('audio')

export function synthesize(voiceId: string, text: string, speed: number, sampleRate?: number) {
    return http.post<Blob>(`${BACKEND.api}/tts/synthesize`, {voice: voiceId, text, speed, sample_rate: sampleRate})
        .catch(handleHttpError)
}

export function synthesizeDemo(voiceId: string, text: string, speed: number) {
    return http.post<Blob>(`${BACKEND.api}/demo/tts/synthesize`, {voice: voiceId, text, speed})
        .catch(handleHttpError)
}

export type Voice = {
    id: string
    name: string
    version: string
    language?: 'ru' | 'kk'
}

let voiceCache: Promise<Voice[]> | undefined

currentUserChangeDispatcher.subscribe(() => voiceCache = undefined)

export function getVoices() {
    return voiceCache ||= http.get<{data: Voice[]}>(`${BACKEND.api}/tts/voices`)
        .then(({data}) => data)
        .catch(error => {
            voiceCache = undefined
            throw error
        })
        .catch(handleHttpError)
}

// TODO убрать, когда бэкенд начнет возвращать лимит
export const demoSynthesisCharacterLimit = 500

let demoVoiceCache: Promise<Voice[]> | undefined

export function getDemoVoices() {
    return demoVoiceCache ||= http.get<{data: Voice[]}>(`${BACKEND.api}/demo/tts/voices`)
        .then(({data}) => data)
        .catch(error => {
            demoVoiceCache = undefined
            throw error
        })
        .catch(handleHttpError)
}

export function playAudio(src: string) {
    audioElement.src = src

    return audioElement.play()
}

export function pauseAudio() {
    audioElement.pause()
}

export function resumeAudio() {
    audioElement.play()
}

export function subscribeOnEvents(handlers: Partial<Record<keyof HTMLMediaElementEventMap, () => void>>) {
    const items = Object.entries(handlers)

    items.forEach(([key, handler]) => audioElement.addEventListener(key, handler))

    return () => items.forEach(([key, handler]) => audioElement.removeEventListener(key, handler))
}
