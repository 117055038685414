import {Dropdown} from 'primereact/dropdown'
import {InputTextarea} from 'primereact/inputtextarea'
import {useEffect, useState} from 'react'

import Icon from './Icon'
import Progress from './Progress'
import OverallProgress from '~/components/OverallProgress'
import {trl, trlObject} from '~/services/intl'
import {showErrorPopup} from '~/services/popup'
import {getVoices, pauseAudio, playAudio, synthesize} from '~/services/synthesis'
import {useCurrentUser} from '~/services/user'
import {formatDate} from '~/utils/formatDate'
import {saveFile} from '~/utils/saveFile'

import type {DropdownProps} from 'primereact/dropdown'
import type {Voice} from '~/services/synthesis'

const getDefaultText = (lang: 'ru' | 'kk' | undefined) => lang ? trlObject(`_synthesis.text.default.${lang}`).join('') : ''

const getSpeeds = () => Object.entries(trlObject('_synthesis.speeds'))
    .map(([value, label]) => ({
        value: +value,
        label,
    }))
    .sort((speed1, speed2) => speed2.value - speed1.value)

const getSampleRates = () => Object.entries(trlObject('_synthesis.sample-rate'))
    .map(([value, label]) => ({
        value: +value,
        label,
    }))
    .sort((option1, option2) => option2.value - option1.value)

const initialEmptyVoices: Voice[] = []

export default function() {
    const [text, setText] = useState('')
    const [voices, setVoices] = useState(initialEmptyVoices)
    const [voiceId, setVoiceId] = useState('')
    const speeds = getSpeeds()
    const sampleRates = getSampleRates()
    const [speed, setSpeed] = useState(1)
    const [sampleRate, setSampleRate] = useState(sampleRates[0].value)
    const [ready, setReady] = useState(true)
    const [currentUser] = useCurrentUser()

    useEffect(
        () => {
            getVoices()
                .catch(showErrorPopup)
                .catch(() => [])
                .then((voices): Voice[] => voices.length == 0
                    ? [{
                        id: '',
                        name: trl('Нет доступных голосов'),
                        version: '',
                    }]
                    : voices
                )
                .then(voices => {
                    setVoices(voices)
                    setVoiceId(voices[0].id)
                    setText(getDefaultText(voices[0].language))
                })
        },
        [],
    )
    const play = () => {
        pauseAudio()
        setReady(false)

        synthesize(voiceId, text, speed, sampleRate)
            .then(URL.createObjectURL)
            .then(playAudio)
            .catch(showErrorPopup)
            .finally(() => setReady(true))
    }

    function download() {
        setReady(false)

        const filename = `${trl('_synthesis.filename', formatDate(new Date), text.slice(0, 20))}.wav`

        synthesize(voiceId, text, speed, sampleRate)
            .then(blob => saveFile([blob], filename, 'audio/wav'))
            .catch(showErrorPopup)
            .finally(() => setReady(true))
    }

    if (voices == initialEmptyVoices)
        return (
            <div className='page__main row row_center'>
                <OverallProgress/>
            </div>
        )

    const selectedVoiceTemplate = (option: Voice | undefined, props: DropdownProps) => {
        if (option)
            return (
                <div className='row row_gap-2'>
                    <div className={`flag flag-${option.language}`}/>
                    <div>{option.name}</div>
                </div>
            )

        return <span>{props.placeholder}</span>
    }

    const voiceOptionTemplate = (option: Voice) =>
        <div className='row row_gap-2'>
            <div className={`flag flag-${option.language}`}/>
            <div>{option.name}</div>
        </div>

    return <div className='page__main page__main_synthesis'>
        <div className='synthesis row row_col row_stretch'>
            <div className='synthesis__textarea'>
                <InputTextarea
                    className='synthesis__textarea'
                    value={text}
                    onChange={({target}) => setText(target.value)}
                    readOnly={!ready}
                />
                {text.length > (currentUser?.limits.tts_characters_per_request || 0) &&
                    <div className='error'>
                        {trl('Ограничение длины %0 символов', currentUser?.limits.tts_characters_per_request)}
                    </div>
                }
            </div>
            <div className='synthesis__control'>
                <div className='row row_between'>
                    <div className='synthesis__control-select'>
                        <div className='row row_col'>
                            <label className='grid grid_rg-2'>
                                <span>
                                    {trl('Голос синтеза')}
                                </span>
                                <Dropdown
                                    className='synthesis__dropdown'
                                    options={voices}
                                    optionLabel='name'
                                    optionValue='id'
                                    value={voiceId}
                                    onChange={({value}: {value: string}) => {
                                        setVoiceId(value)

                                        const [oldLanguage, newLanguage] = [voiceId, value]
                                            .map(id => voices.find(voice => voice.id == id)?.language)

                                        if (oldLanguage != newLanguage)
                                            setText(getDefaultText(newLanguage))
                                    }}
                                    valueTemplate={selectedVoiceTemplate}
                                    itemTemplate={voiceOptionTemplate}
                                    disabled={!ready || !voiceId}
                                />
                            </label>
                        </div>
                    </div>
                    <div className='synthesis__control-select'>
                        <div className='row row_col'>
                            <label className='grid grid_rg-2'>
                                <span>
                                    {trl('Скорость воспроизведения')}
                                </span>
                                <Dropdown
                                    className='synthesis__dropdown'
                                    options={speeds}
                                    value={speed}
                                    onChange={({value}: {value: number}) => setSpeed(value)}
                                    disabled={!ready}
                                />
                            </label>
                        </div>
                    </div>
                    <div className='synthesis__control-select'>
                        <div className='row row_col'>
                            <label className='grid grid_rg-2'>
                                <span>
                                    {trl('Частота дискретизации')}
                                </span>
                                <Dropdown
                                    className='synthesis__dropdown'
                                    options={sampleRates}
                                    value={sampleRate}
                                    onChange={({value}: {value: number}) => setSampleRate(value)}
                                    disabled={!ready}
                                />
                            </label>
                        </div>
                    </div>
                    <div className='col synthesis__buttons row row_nowrap row_between'>
                        {ready
                            ? <>
                                <div className='col'>
                                    <button
                                        onClick={play}
                                        disabled={!voiceId}
                                    >
                                        <Icon className='synthesis__play-icon' name='play'/>
                                    </button>
                                </div>
                                <div className='col'>
                                    <button
                                        onClick={download}
                                        className='synthesis__button'
                                        disabled={!voiceId}
                                    >
                                        <i className='pi pi-download synthesis__icon-download'/>
                                    </button>
                                </div>
                            </>
                            : <Progress/>
                        }
                    </div>
                </div>
            </div>

        </div>
    </div>
}
