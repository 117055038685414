import {useEffect, useState} from 'react'

export type Reducer<State, Action> = (state: State, action: Action) => State

type Listener<State> = (state: State) => void

export function getUseSharedReducer<State, Action>(reducer: Reducer<State, Action>, initial: State) {
    let state = initial
    const listeners: Listener<State>[] = []

    function subscribe(listener: Listener<State>) {
        listeners.push(listener)

        return () => unsubscribe(listener)
    }

    function unsubscribe(listener: Listener<State>) {
        const index = listeners.indexOf(listener)

        listeners.splice(index, 1)
    }

    function notify() {
        for (const listener of listeners)
            listener(state)
    }

    function dispatch(action: Action) {
        const newState = reducer(state, action)

        if (newState !== state) {
            state = newState
            notify()
        }
    }

    function getState() {
        return state
    }

    function useSharedReducer(): [State, typeof dispatch] {
        const [_data, _setData] = useState(state)

        useEffect(
            () => subscribe(_setData),
            []
        )

        return [_data, dispatch]
    }

    useSharedReducer.dispatch = dispatch
    useSharedReducer.subscribe = subscribe
    useSharedReducer.getState = getState

    return useSharedReducer
}
