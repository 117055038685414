import clsx from 'clsx'
import {Button} from 'primereact/button'
import {Dropdown} from 'primereact/dropdown'
import {InputTextarea} from 'primereact/inputtextarea'
import {useEffect, useState} from 'react'

import OverallProgress from '../OverallProgress'
import Progress from '../Progress'
import {trl, trlObject} from '~/services/intl'
import {showErrorPopup} from '~/services/popup'
import {subscribeOnEvents, getDemoVoices, pauseAudio, playAudio, resumeAudio, synthesizeDemo, demoSynthesisCharacterLimit} from '~/services/synthesis'

import type {DropdownProps} from 'primereact/dropdown'
import type {Voice} from '~/services/synthesis'

const getDefaultText = (lang: 'ru' | 'kk' | undefined) => lang ? trlObject(`_synthesis.text.default.${lang}`).join('') : ''

const getSpeeds = () => Object.entries(trlObject('_synthesis.speeds'))
    .map(([value, label]) => ({
        value: +value,
        label,
    }))
    .sort((speed1, speed2) => speed2.value - speed1.value)

type SoundStatus = 'playing' | 'paused' | 'stopped'

const initialEmptyVoices: Voice[] = []

export default function() {
    const {start, resume, pause, synthesizeSpeech} = trlObject('_landing.boxes')['4'].blocks.speechSynthesis

    const [audio, setAudio] = useState<string>()
    const [soundStatus, setSoundStatus] = useState<SoundStatus>('stopped')
    const [text, setText] = useState('')
    const [voices, setVoices] = useState(initialEmptyVoices)
    const [voiceId, setVoiceId] = useState('')
    const speeds = getSpeeds()
    const [speed, setSpeed] = useState(1)
    const [ready, setReady] = useState(true)

    useEffect(
        () => {
            getDemoVoices()
                .then(voices => {
                    setVoices(voices)
                    setVoiceId(voices[0].id)
                    setText(getDefaultText(voices[0].language))
                })
                .catch(showErrorPopup)
        },
        [],
    )

    useEffect(() => subscribeOnEvents({
        ended: () => setSoundStatus('stopped'),
        play: () => setSoundStatus('playing'),
        pause: () => setSoundStatus('paused'),
    }), [])

    useEffect(() => {
        if (soundStatus == 'paused' && !audio)
            setSoundStatus('stopped')
    }, [audio, soundStatus, text])

    const updateText = (value: string) => {
        if (value != text)
            setAudio(undefined)
        setText(value)
    }

    const handleClick = () => {
        switch (soundStatus) {
        case 'stopped':
            return audio ? playAudio(audio) : synthesize()
        case 'playing':
            return pauseAudio()
        case 'paused':
            return resumeAudio()
        }
    }

    const synthesize = () => {
        setReady(false)

        // TODO порефакторить проигрывание аудио (и не забыть про revokeObjectURL)
        synthesizeDemo(voiceId, text, speed)
            .then(URL.createObjectURL)
            .then(objectUrl => {
                playAudio(objectUrl)
                setAudio(objectUrl)
            })
            .catch(showErrorPopup)
            .finally(() => setReady(true))
    }

    const getButtonText = () => {
        if (soundStatus == 'stopped')
            return audio ? start : synthesizeSpeech

        return soundStatus == 'paused' ? resume : pause
    }

    if (voices == initialEmptyVoices)
        return <div className='landing-recognition row row_center row_gap-6'>
            <OverallProgress/>
        </div>

    const selectedVoiceTemplate = (option: Voice | undefined, props: DropdownProps) => {
        if (option)
            return (
                <div className='row row_gap-2'>
                    <div className={`flag flag-${option.language}`}/>
                    <div>{option.name}</div>
                </div>
            )

        return <span>{props.placeholder}</span>
    }

    const voiceOptionTemplate = (option: Voice) =>
        <div className='row row_gap-2'>
            <div className={`flag flag-${option.language}`}/>
            <div>{option.name}</div>
        </div>

    const exceededLimit = text.length > demoSynthesisCharacterLimit

    return (
        <div className='landing-recognition row row_col row row_gap-6'>
            <div className='landing-recognition__content row row_nowrap row_top'>
                <div className='row row_col landing-recognition__textarea-wrapper'>
                    <InputTextarea
                        className={clsx('landing-recognition__textarea', exceededLimit && 'p-invalid')}
                        value={text}
                        onChange={({target}) => updateText(target.value)}
                        readOnly={!ready}
                    />
                    <div
                        className={clsx(
                            'text text_small text_grey landing-recognition__synthesis-hint',
                            exceededLimit && 'text_assertive'
                        )}
                    >
                        {trl('Ограничение длины %0 символов', demoSynthesisCharacterLimit)}
                    </div>
                </div>
                <div className='row row_col row_gap-10'>
                    <Dropdown
                        className='landing-recognition__model-selector'
                        options={voices}
                        optionLabel='name'
                        optionValue='id'
                        value={voiceId}
                        onChange={({value}: {value: string}) => {
                            setVoiceId(value)
                            setAudio(undefined)

                            const [oldLanguage, newLanguage] = [voiceId, value]
                                .map(id => voices.find(voice => voice.id == id)?.language)

                            if (oldLanguage != newLanguage)
                                setText(getDefaultText(newLanguage))
                        }}
                        valueTemplate={selectedVoiceTemplate}
                        itemTemplate={voiceOptionTemplate}
                        disabled={!ready}
                    />
                    <Dropdown
                        className='landing-recognition__model-selector'
                        options={speeds}
                        value={speed}
                        onChange={({value}: {value: number}) => {
                            setSpeed(value)
                            setAudio(undefined)
                        }}
                        disabled={!ready}
                    />
                </div>
            </div>
            <div className='landing-recognition__footer row row_gap-4'>
                <Button
                    type='button'
                    className='landing-blue-button'
                    onClick={handleClick}
                    disabled={!ready || !text || exceededLimit}
                >
                    {getButtonText()}
                </Button>
                {!ready && <Progress className='landing-recognition__loader'/>}
            </div>
        </div>
    )
}
